:local(.support-main) {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  z-index: 10000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #444;
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 5em;
}

:local(.support-content) {
  text-align: center;
  margin: 1em;
  a {
    font-size: smaller;
    color: white;
  }
  input {
    width: 15em;
    background: white;
    color: black;
    font-size: 12pt;
    padding: 0.4em;
    border-radius: 10px;
    border: none;
  }
  a.copy-link {
    margin-left: 0.5em;
    font-size: 12pt;
  }
  :local(.unsupported-langs) {
    span {
      white-space: nowrap;
    }
  }
  table {
    margin: auto;
  }
}

:local(.details) {
  text-align: left;
  tr td:first-child {
    padding-right: 1em;
  }
}
